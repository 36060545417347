import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormControlLabel,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
  Table,
} from "reactstrap";
import DownloadIcon from '@mui/icons-material/Download';
import { event } from "react-ga";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LinkedCamera } from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  getGeneralInformation,
  getLicenceInfo,
  getUserDetails,
} from "../../constants/apiList";

const MedicalLabApproval = (user) => {
  const [generalInfoData, setGeneralInfoData] = useState([]);
  const [labName, setLabName] = useState();
  const [yearStablish, setYearStablish] = useState();
  const [registrationNumber, setRegistrationNumber] = useState();
  const [numberOfLocation, setNumberOfLocation] = useState("");
  const [ownerName, setOwnerName] = useState();
  const [ownerPhone, setOwnerPhone] = useState();
  const [ownerEmail, setOwnerEmail] = useState();
  const [labAddress, setLabAddress] = useState();
  const [labPhoneNumber, setLabPhoneNumber] = useState();
  const [pincode, setPinCode] = useState();
  const [licenses1, setLicenses1] = useState();
  const [licenses2, setLicenses2] = useState();
  const [licenses3, setLicenses3] = useState();
  const [nablAccreditation, setNablAccreditation] = useState();
  const [isoCertificate, setIsoCertificate] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState(null);
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [accessNeeded, setAccessNeeded] = useState(false);
  const [sundayIsHoliday, setSundayIsHoliday] = useState(false);
  const [licenses, setLicenses] = useState([
    { license: "", documentLink: "" },
    { license: "", documentLink: "" },
    { license: "", documentLink: "" },
  ]);
  const [partnerType, setPartnerType] = useState(null);
  const [labPartnerCertificate, setLabPartnerCertificate] = useState([]);
  const [labPartnerSampleTest, setLabPartnerSampleTest] = useState([]);
  const [NABLreports, setNABLReports] = useState(false);
  const [ISOreports, setISOReports] = useState(false);
  const [uploadFlag, setUploadFlag] = useState(false);
  const [editedRows, seteditedRows] = useState([]);
  const [serviceAreaPinCode, setServiceAreaPinCode] = useState([]);
  const [pinCodesString, setPinCodesString] = useState("");
  const [labMainAddress, setLabMainAddress] = useState();
  const [userId, setUserId] = useState(null);
  const [rows, setRows] = useState([
    {
      id: null,
      labDirectorName: "",
      phoneNumber: "",
      email: "",
      accessNeeded: false,
    },
  ]);
  const [timings, setTimings] = useState({
    Monday: { from: "", to: "" },
    Tuesday: { from: "", to: "" },
    Wednesday: { from: "", to: "" },
    Thursday: { from: "", to: "" },
    Friday: { from: "", to: "" },
    Saturday: { from: "", to: "" },
    Sunday: { from: "", to: "" },
  });
  const [sampleTimings, setSampleTimings] = React.useState({
    Monday: { from: "", to: "" },
    Tuesday: { from: "", to: "" },
    Wednesday: { from: "", to: "" },
    Thursday: { from: "", to: "" },
    Friday: { from: "", to: "" },
    Saturday: { from: "", to: "" },
    Sunday: { from: "", to: "" },
  });

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const handleTimingChange = (day, field, value) => {
    setTimings((prev) => {
      const updatedTimings = {
        ...prev,
        [day]: {
          ...prev[day],
          [field]: value,
        },
      };

      // Check if any field in timings is filled and clear the error
      const isTimingsValid = Object.values(updatedTimings).some(
        (day) => day.from.trim() !== "" && day.to.trim() !== ""
      );

      if (isTimingsValid) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          timings: false,
        }));
      }

      return updatedTimings;
    });
  };

  const handleSampleTimingChange = (day, field, value) => {
    setSampleTimings((prev) => {
      const updatedSampleTimings = {
        ...prev,
        [day]: { ...prev[day], [field]: value },
      };

      // Check if any field in sampleTimings is filled and clear the error
      const isSampleTimingsValid = Object.values(updatedSampleTimings).some(
        (day) => day.from.trim() !== "" && day.to.trim() !== ""
      );

      if (isSampleTimingsValid) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          sampleTimings: false,
        }));
      }

      return updatedSampleTimings;
    });
  };

  const addRow = () => {
    setRows([
      ...rows,
      {
        id: null,
        labDirectorName: "",
        phoneNumber: "",
        email: "",
        accessNeeded: false,
      },
    ]);
  };
  const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"));
  const [isoStandards, setIsoStandards] = useState([""]); // Initial field

  const handleISOStandardChange = (event, index) => {
    const updatedStandards = [...isoStandards];
    updatedStandards[index] = event.target.value;
    setIsoStandards(updatedStandards);
  };

  const addISOStandardField = () => {
    setIsoStandards([...isoStandards, ""]);
  };
  const handleChange = (index, e, checked) => {
    const { name } = e.target;

    const newRows = [...rows];
    if (name.startsWith("accessNeeded")) {
      newRows[index]["accessNeeded"] = checked; // Update accessNeeded directly
    } else {
      newRows[index][name] = e.target.value; // Update other properties
    }
    setRows(newRows);
  };

  const handleDirectorChange = (id, e) => {
    const newRows = [...generalInfoData?.labUsersDto];
    const index = newRows.findIndex((row) => row.id === id);
    if (index !== -1) {
      newRows[index][e.target.name] = e.target.value;
      seteditedRows(newRows);
    }
  };

  const handleDirector2Change = (id, fieldName, value) => {
    const newRows = generalInfoData?.labUsersDto.map((row) => {
      if (row.id === id) {
        return { ...row, [fieldName]: value };
      }
      return row;
    });
    setGeneralInfoData((prevData) => ({
      ...prevData,
      labUsersDto: newRows,
    }));
    seteditedRows(newRows);
  };

  const handleLabName = (event) => {
    setLabName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      LabName: false,
    }));
  };
  const handleYearStablish = (event) => {
    setYearStablish(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      yearStablish: false,
    }));
  };
  const handleRegistration = (event) => {
    setRegistrationNumber(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      registrationNumber: false,
    }));
  };
  const handleNumberofLocation = (event) => {
    setNumberOfLocation(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      numberOfLocation: false,
    }));
  };
  const handleOwnerName = (event) => {
    setOwnerName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      ownerName: false,
    }));
  };
  const handleOwnerPhone = (event) => {
    setOwnerPhone(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      ownerPhone: false,
    }));
  };
  const handleOwnerEmail = (event) => {
    setOwnerEmail(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      ownerEmail: false,
    }));
  };
  const handleLabAddress = (event) => {
    setLabAddress(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labAddress: false,
    }));
  };
  const handlelabPhoneNumber = (event) => {
    setLabPhoneNumber(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labPhoneNumber: false,
    }));
  };
  const handlePinCode = (event) => {
    setPinCode(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      pincode: false,
    }));
  };

  const handleLicenses = (event) => {
    setLicenses1(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      licenses1: false,
    }));
  };
  const handleNablAccreditationChange = (value) => {
    setNablAccreditation(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      nablAccreditation: value === null,
    }));
  };
  const handleIsoCertification = (value) => {
    setIsoCertificate(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      isoCertificate: value === null,
    }));
  };
  const handleFileUpload = (value) => {
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      fileUpload: value === null || value.length === 0,
      reports: false,
    }));
  };

  const initialRows = generalInfoData?.labUsersDto?.map((user) => ({
    labDirectorName: user.labDirectorName,
    phoneNumber: user.phoneNumber,
    email: user.email,
    accessNeeded: user.accessNeeded,
  }));
  const transformTimingsToLabTiming = (timings) => {
    return Object.keys(timings).map((day) => ({
      day: day,
      startTime: timings[day].from,
      endTime: timings[day].to,
    }));
  };
  const transformLabTimingToTimings = (labTimingArray) => {
    return labTimingArray.reduce((acc, item) => {
      acc[item.day] = {
        from: item.startTime,
        to: item.endTime,
      };
      return acc;
    }, {});
  };
  const handleSubmit = async (status) => {
    const partnerType = user?.approval;
    const userId = user?.selectedUser?.id;
    const formData = {
      approvedDetail : null,
      userId : userId,
      approveFlag: status,
      partnerType: partnerType,
    }
    try {
      await AxiosInstance.post(`admin/profile-approve`, formData)
        .then((response) => {
          if(status == true){
            Swal.fire({
              icon: "success",
              title: "Approve!",
              text: "Profile Approved SuccessFully!",
            });
          }
          else{
            Swal.fire({
              icon: "success",
              title: "Decline!",
              text: "Profile Decline SuccessFully!",
            });
          }
        })
        .catch((err) => {
          console.log("Upload error:", err);
        });
    } catch (error) {
      console.error("Error uploading invoice:", error);
    }
  };
  const handleLicenseChange = (index, value) => {
    const newLicenses = [...licenses];
    newLicenses[index].license = value;
    setLicenses(newLicenses);
  };
  const handleFileChange = async (event, index) => {
    event.preventDefault();
    const file = event.target.files[0];
    setSelectedFile(file);

    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("licenseNames", licenses[index].license);
      AxiosInstance.post("/lab-partner/licenses-upload", formData)
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setUploadFlag((prev) => !prev);
          setIsOpen(false);
          setSelectedFile(null);
          setFileName(null);
          GetLicenceInfo();
        })
        .catch((error) => {
          //setError("An
        });
    }
  };
  const handleISOChange = async (event, selectedRowData) => {
    event.preventDefault();
    const file = event.target.files[0];
    setSelectedFile(file);
    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("licenseNames", selectedRowData);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        ISOreports: false,
      }));
      AxiosInstance.post("/lab-partner/licenses-upload", formData)
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setUploadFlag((prev) => !prev);
          setISOReports(true);
          setIsOpen(false);
          setSelectedFile(null);
          setFileName(null);
        })
        .catch((error) => {
          //setError("An
        });
    }
  };
  const handleNABLChange = async (event) => {
    event.preventDefault();
    const NABLCertificate = "NABLCertificate";
    const file = event.target.files[0];
    setSelectedFile(file);
    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      formData.append("licenseNames", NABLCertificate);
      formData.append("file", file);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        NABLreports: false,
      }));
      AxiosInstance.post("/lab-partner/licenses-upload", formData)
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setNABLReports(true);
          setUploadFlag((prev) => !prev);
          setIsOpen(false);
          setSelectedFile(null);
          setFileName(null);
        })
        .catch((error) => {
          console.log("error: ", error);
          //setError("An
        });
    }
  };
  const handleMultipleChange = async (event) => {
    event.preventDefault();
    const fileArray = Array.from(event.target.files);
    // if (files.length < 8) {
    //   toast.error("Please select at least 8 files.");
    //   return;
    // }
    // setFileName(file.name);
    // setSelectedFile(file);
    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      for (const file of fileArray) {
        formData.append("file", file);
      }
      AxiosInstance.post("/lab-partner/sample-test-upload", formData)
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setISOReports(true);
          setUploadFlag((prev) => !prev);
          setIsOpen(false);
          setSelectedFile(null);
          setFileName(null);
        })
        .catch((error) => {
          //setError("An
        });
    }
  };
  const GetLicenceInfo = async () => {
    // await AxiosInstance.get(`${getLicenceInfo}`)
    //   .then((response) => {
    //     // const fetchedLicenses = response?.labPartnerLicense.length > 0 ?  response?.labPartnerLicense : licenses;
    //     // const sanitizedLicenses = fetchedLicenses.map(license =>
    //     //   ({ ...license, license: license.license === "undefined" ? '' : license.license })
    //     // );
    //     // setLicenses(sanitizedLicenses);

    //     const fetchedLicenses = response?.labPartnerLicense || [];
    //     const sanitizedLicenses = fetchedLicenses.map((license) => ({
    //       ...license,
    //       license: license.license === "undefined" ? "" : license.license,
    //     }));

    //     // Ensure there are exactly three licenses
    //     const licensesToSet = [...sanitizedLicenses];
    //     while (licensesToSet.length < 3) {
    //       licensesToSet.push({ license: "", documentLink: "" });
    //     }
    //     if (licensesToSet.length > 3) {
    //       licensesToSet.length = 3;
    //     }

    //     setLicenses(licensesToSet);

    //     // setLicenses(response?.labPartnerLicense);
    //     setLabPartnerSampleTest(response?.labPartnerSampleTest);
    //     setLabPartnerCertificate(response?.labPartnerCertificate);
    //   })
    //   .catch((err) => {
    //     console.log("err: ", err);
    //   });
  };

  const handleViewFile = (filePath) => {
    // Check if the file is viewable (e.g., PDF or image) and set a flag
    const fileExtension = filePath.split('.').pop().toLowerCase();
    if (fileExtension === "pdf" || ["jpg", "jpeg", "png"].includes(fileExtension)) {
      // Set the file viewable flag (this might be done based on the file type or other logic)
      setLicenses({
        ...licenses,
        documentLink: filePath,
        isFileViewable: true, // Only allow view if it's a supported file type
      });
    } else {
      alert("File type not supported for preview.");
    }
  };

  const handleDownload = (filePath) => {
  
    const downloadApi = "lab-partner/download-lab-file/LAB_DOCUMENT";
    const encodedUrl = encodeURIComponent(filePath);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };
  
    fetch(`${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
  
        const contentDispositionHeader = response.headers.get("Content-Disposition");
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              const downloadLink = document.createElement("a");
              downloadLink.href = blobUrl;
              downloadLink.download = filename;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(blobUrl);
            });
          }
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };
  
  // Handle file view in iframe
  const handleView = (filePath) => {
    const fileExtension = filePath.split('.').pop().toLowerCase();
    
    // Check if the file type is viewable (PDF or image)
    if (fileExtension === "pdf" || ["jpg", "jpeg", "png"].includes(fileExtension)) {
      // Display in an iframe
      setCurrentFile(filePath); // Assuming setCurrentFile updates the iframe src in your component
    } else {
      toast.error("This file type cannot be viewed directly.");
    }
  };
  
  const handleDelete = (filePath, gymPhoto) => {
     const id = gymPhoto.id
   
     const downloadApi = "/lab-partner/delete-lab-file";
     const encodedUrl = encodeURIComponent(filePath);
     const authToken = window.sessionStorage.getItem("token");
     const requestOptions = {
       method: "DELETE",
       headers: {
         Authorization: `Bearer ${authToken}`,
       },
       redirect: "follow",
     };
   
     fetch(`${process.env.REACT_APP_SERVER_URL}${downloadApi}/${id}/LAB_DOCUMENT`, requestOptions)
       .then((response) => {
         if(response.ok){
           Swal.fire({
             title: 'Deleted Successfully!',
             text: 'The document was deleted successfully.',
             icon: 'success',
             confirmButtonText: 'OK',
           });
         }
         if (!response.ok) {
           throw new Error(`Network response was not ok: ${response.status}`);
         }
   
         const contentDispositionHeader = response.headers.get("Content-Disposition");
         if (contentDispositionHeader) {
           const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);
           if (matches != null && matches[1]) {
             const filename = matches[1].replace(/['"]/g, "");
             response.blob().then((blobData) => {
               const blobUrl = window.URL.createObjectURL(blobData);
               const downloadLink = document.createElement("a");
               downloadLink.href = blobUrl;
               downloadLink.download = filename;
               document.body.appendChild(downloadLink);
               downloadLink.click();
               document.body.removeChild(downloadLink);
               window.URL.revokeObjectURL(blobUrl);
             });
           } else {
             toast.error("Filename not found in Content-Disposition header");
           }
         } 
       })
       .catch((error) => {
         toast.error(`Error fetching or downloading the file: ${error}`);
       });
   }
  const GetGeneralInformation = async () => {
    await AxiosInstance.get(`${getGeneralInformation}`)
      .then((response) => {
        setLabMainAddress(response?.labMainAddress);
        setLabPhoneNumber(response?.labMainAddress?.labPhoneNumber);
        setPinCode(response?.labMainAddress?.labPinCode);
        setGeneralInfoData(response);
        setLabName(response?.labName);
        setYearStablish(response?.establishedYear);
        setRegistrationNumber(response?.registrationNumber);
        setNumberOfLocation(response?.numberOfLocation);
        setLabAddress(response?.labMainAddress?.labAddress);
        setLabPhoneNumber(response?.labMainAddress?.labPhoneNumber);
        setPinCode(response?.labMainAddress?.labPinCode);
        setServiceProvideWithSameAddress(
          response?.labMainAddress?.serviceProvideWithSameAddress
        );
        setTimings(
          response?.labMainAddress?.labTiming
            ? transformLabTimingToTimings(response?.labMainAddress?.labTiming)
            : timings
        );

        // setWeekDayFrom(response?.labMainAddress?.workDayStartTime);
        // setWeekDayTo(response?.labMainAddress?.workDayEndTime);
        // setWeekEndFrom(response?.labMainAddress?.workDayWeekendStartTime);
        // setWeekEndTo(response?.labMainAddress?.workDayWeekendEndTime);
        if (response?.labMainAddress?.serviceProvideWithSameAddress) {
          setSampleTimings(
            user?.selectedUser?.labMainAddress?.labServiceAreaPinCode?.sampleCollectionTiming
          );
          // setWorkDayStartTime(
          //   response?.labMainAddress?.labServiceAreaPinCode?.workDayStartTime
          // );
          // setWorkDayEndTime(
          //   response?.labMainAddress?.labServiceAreaPinCode?.workDayEndTime
          // );
          // setWorkDayWeekendStartTime(
          //   response?.labMainAddress?.labServiceAreaPinCode
          //     ?.workDayWeekendStartTime
          // );
          // setWorkDayWeekendEndTime(
          //   response?.labMainAddress?.labServiceAreaPinCode
          //     ?.workDayWeekendEndTime
          // );
          setServiceAreaPinCode(response?.labMainAddress);
        }
        setIsoCertificate(response?.labIso);
        setNablAccreditation(response?.labNabl);
        setSundayIsHoliday(response?.sundayIsHoliday);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  useEffect(() => {
    GetGeneralInformation();
    GetLicenceInfo();
    setEmail(userDetails?.email);
    setPhoneNumber(userDetails?.phoneNumber);
    setFirstName(userDetails?.firstName);
    setLastName(userDetails?.lastName);
    setPinCodesString(
      serviceProvideWithSameAddress?.labServiceAreaPinCode?.serviceAreaPinCode.join(
        ", "
      )
    );

  }, []);
  useEffect(() => {
    GetLicenceInfo();
  }, []);

  const nablCertificates = labPartnerCertificate
    .filter((certificate) => certificate.license === "NABLCertificate")
    .map((certificate) => certificate.documentLink);

  const isoCertificates = labPartnerCertificate
    .filter((certificate) => certificate.license === "ISOCertificate")
    .map((certificate) => certificate.documentLink);

  const [pincodeRow, setPincodeRow] = useState([{ serviceAreaPinCode: "" }]);
  const addPincode = () => {
    setPincodeRow([...pincodeRow, { serviceAreaPinCode: "" }]);
  };

  // Function to handle pin code change
  const handlePincodeChange = (index, e) => {
    const updatedPincodeRow = [...pincodeRow];
    updatedPincodeRow[index].serviceAreaPinCode = e.target.value;
    setPincodeRow(updatedPincodeRow);
  };
  const [serviceProvideWithSameAddress, setServiceProvideWithSameAddress] =
    useState();
  return (
    <>
      <div style={{ display: "none" }}>
        <input
          type="file"
          accept=".jpg, .jpeg, .pdf, .png"
          id="fileUpload"
          className="file-upload-input"
          name="file"
          onChange={(event) => handleFileChange(event, licenses1)}
        />
        {isLoading == true ? (
          <div>
            <Spinner />
            <br />
            <p>Please wait your prescription is uploading</p>
          </div>
        ) : (
          <>Attach</>
        )}
      </div>
      <div style={{ display: "flex" }}>
        <h3>Main Office</h3> &nbsp; &nbsp;
        <BorderColorIcon />
      </div>
      <Form
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          marginTop: "25px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          fontSize: "18px",
        }}
      >
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleEmail">
                <b>Name of the Lab</b>
              </Label>
              <Input
                disabled
                id=""
                name="name"
                value={
                  user?.selectedUser?.labName
                }
                onChange={(e) => {
                  handleLabName(e);
                }}
                placeholder="Enter name of your Lab"
                type="text"
                style={{
                  borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="examplePassword">
                <b>Year Established</b>
              </Label>
              <Input
                id=""
                name="yearEstablished"
                value={
                 user?.selectedUser?.establishedYear
                }
                onChange={(e) => {
                  handleYearStablish(e);
                }}
                placeholder=""
                type="date"
                style={{
                  borderColor: fieldErrors.yearStablish ? "red" : "lightgrey",
                }}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={6}>
            <FormGroup>
              <Label for="exampleEmail">
                <b>Registration Number</b>
              </Label>
              <Input
                id=""
                name="registrationNumber"
                value={
                 user?.selectedUser?.registrationNumber
                }
                onChange={(e) => {
                  handleRegistration(e);
                }}
                placeholder="Enter Registration Number"
                disabled
                type="text"
                style={{
                  borderColor: fieldErrors.registrationNumber
                    ? "red"
                    : "lightgrey",
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="examplePassword">
                <b>Number of Locations</b>
              </Label>
              <Input
                id=""
                name="NumofLocation"
                value={user?.selectedUser?.numberOfLocation}
                onChange={(e) => {
                  handleNumberofLocation(e);
                }}
                placeholder="Enter Number of Location"
                type="number"
                min={0}
                style={{
                  borderColor: fieldErrors.numberOfLocation
                    ? "red"
                    : "lightgrey",
                }}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={4}>
            <FormGroup>
              <Label for={`labDirectorName`}>
                <b>Director / Founder / Owner Name (Primary)</b>
              </Label>
              <Input
                disabled
                id={`labDirectorName`}
                name="labDirectorName"
                placeholder="Enter Name"
                type="text"
                value={user?.selectedUser?.firstName + " " + user?.selectedUser?.lastName}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for={`phoneNumber`}>
                <b>Phone Number</b>
              </Label>
              <Input
                disabled
                id={`phoneNumber`}
                name="phoneNulamber"
                placeholder="Enter Phone Number"
                type="number"
                value={user?.selectedUser?.phoneNumber}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for={`emailIndex`}>
                <b>Email Id</b>
              </Label>
              <Input
                disabled
                id={`email`}
                name="email"
                placeholder="Enter Email Id"
                type="email"
                value={user?.selectedUser?.email}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        {user?.selectedUser?.labUsers.map((row, index) => (
            <>
              <Row key={index} className="mt-4">
                <Col md={3}>
                  <FormGroup>
                    <Label for={row.id}>
                      <b>Director / Founder / Owner Name</b>
                    </Label>
                    <Input
                    disabled
                      id={row.id}
                      name="labDirectorName"
                      placeholder="Enter Name"
                      type="text"
                      value={row.labDirectorName}
                      onChange={(e) => {
                        // handleChange(index, e);
                        // setOwnerName(e.target.value);
                        handleDirectorChange(row.id, e);
                        handleOwnerName(e);
                      }}
                      style={{
                        borderColor: fieldErrors.ownerName
                          ? "red"
                          : "lightgrey",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for={row.id}>
                      <b>Phone Number</b>
                    </Label>
                    <Input
                    disabled
                      id={row.id}
                      name="phoneNumber"
                      placeholder="Enter Phone Number"
                      type="number"
                      value={row.phoneNumber}
                      onChange={(e) => {
                        // handleChange(index, e);
                        handleDirectorChange(row.id, e);
                        setOwnerPhone(e.target.value);
                        handleOwnerPhone(e);
                      }}
                      style={{
                        borderColor: fieldErrors.ownerPhone
                          ? "red"
                          : "lightgrey",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for={row.id}>
                      <b>Email Id</b>
                    </Label>
                    <Input
                    disabled
                      id={row.id}
                      name="email"
                      placeholder="Enter Email Id"
                      type="email"
                      value={row.email}
                      onChange={(e) => {
                        // handleChange(index, e);
                        setOwnerEmail(e.target.value);
                        handleOwnerEmail(e);
                        handleDirectorChange(row.id, e);
                      }}
                      style={{
                        borderColor: fieldErrors.ownerEmail
                          ? "red"
                          : "lightgrey",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for={`accessNeeded${index}`}>
                      <b>Does this person needs access to this account?</b>
                    </Label>
                    <br></br>
                    <Input
                      name={`accessNeeded${row.id}`}
                      type="radio"
                      checked={!row.accessNeeded}
                      value="false"
                      onChange={(e) => {
                        // handleChange(index, e);
                        setAccessNeeded(false);
                        handleDirector2Change(row.id, "accessNeeded", false);
                      }}
                      disabled
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      No
                    </Label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Input
                      name={`accessNeeded${row.id}`}
                      type="radio"
                      checked={row.accessNeeded}
                      value="true"
                      onChange={(e) => {
                        // handleChange(index, e);
                        setAccessNeeded(true);
                        handleDirector2Change(row.id, "accessNeeded", true);
                      }}
                      disabled
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      Yes
                    </Label>
                  </FormGroup>
                </Col>
                <Col md={1}>
                  <FormGroup></FormGroup>
                </Col>
              </Row>
            </>
          ))}
        <hr style={{ border: "1px solid #000000" }}></hr>
        {/* {labMainAddress &&
            Array.isArray(labMainAddress).map((index, row) => { */}
        <>
          <Row className="mt-4">
            <Col md={4}>
              <FormGroup>
                <Label>
                  <b>Main Office / Lab Address</b>
                </Label>
                <Input
                disabled
                  name="labAddress"
                  placeholder="Enter Address"
                  value={user?.selectedUser?.labMainAddress?.labAddress}
                  type="text"
                  onChange={(e) => {
                    // handleAddressChange(addressIndex, e);
                    setLabAddress(e.target.value);
                    handleLabAddress(e);
                  }}
                  style={{
                    borderColor: fieldErrors.labAddress ? "red" : "lightgrey",
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <b>Phone Number</b>
                </Label>
                <Input
                  name="labPhoneNumber"
                  placeholder="Enter Phone Number of Lab"
                  value={user?.selectedUser?.labMainAddress?.labPhoneNumber}
                  type="number"
                  onChange={(e) => {
                    // handleAddressChange(addressIndex, e);
                    setLabPhoneNumber(e.target.value);
                    handlelabPhoneNumber(e);
                  }}
                  style={{
                    borderColor: fieldErrors.labPhoneNumber
                      ? "red"
                      : "lightgrey",
                  }}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <b>Pin Code</b>
                </Label>
                <Input
                  name="labPinCode"
                  placeholder="Enter PinCode"
                  value={user?.selectedUser?.labMainAddress?.labPinCode}
                  onChange={(e) => {
                    // handleAddressChange(addressIndex, e);
                    handlePinCode(e);
                  }}
                  type="number"
                  style={{
                    borderColor: fieldErrors.pincode ? "red" : "lightgrey",
                  }}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-5">
            {/* <div>
              <Label>
                <b>Lab Working Timings</b>
              </Label>
              <Table bordered responsive style={{ border: "1px solid #ddd" }}>
                <thead>
                  <tr
                    style={{
                      background:
                        "linear-gradient(90deg,royalblue, mediumvioletred)",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Day</th>
                    <th>From</th>
                    <th>To</th>
                  </tr>
                </thead>
                <tbody>
                  {daysOfWeek.map((day, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                        textAlign: "center",
                      }}
                    >
                      <td>
                        <b style={{ color: "#333" }}>{day}</b>
                      </td>
                      <td>
                        <Input
                          type="time"
                          value={timings[day]?.from || ""}
                          onChange={(e) =>
                            handleTimingChange(day, "from", e.target.value)
                          }
                          style={{
                            textAlign: "center",
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          type="time"
                          value={timings[day]?.to || ""}
                          onChange={(e) =>
                            handleTimingChange(day, "to", e.target.value)
                          }
                          style={{
                            textAlign: "center",
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {fieldErrors.timings && (
                <p className="error">
                  Provide atleast any one working timings.
                </p>
              )}
            </div> */}
            <Row>
              <Col>
                <b
                  style={{
                    color: fieldErrors.sampleCollectionService
                      ? "red"
                      : "black",
                  }}
                >
                  Provide sample collection service from here for different
                  pincode ?
                </b>

                &nbsp;&nbsp;&nbsp;
                <Input
                  name="serviceProvideWithSameAddress"
                  type="radio"
                  checked={user?.selectedUser?.labMainAddress?.serviceProvideWithSameAddress === false}
                  onChange={(e) => {
                    setServiceProvideWithSameAddress(false);
                  }}
                  disabled
                />
               
                &nbsp;
                <Label check className="ml-1">
                  No
                </Label>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Input
                  name="serviceProvideWithSameAddress"
                  type="radio"
                  checked={
                    // (numberOfLocation == 0 || numberOfLocation == 1) &&
                    user?.selectedUser?.labMainAddress?.serviceProvideWithSameAddress === true
                  }
                  onChange={(e) => {
                    setServiceProvideWithSameAddress(true);
                  }}
                  disabled
                />
                &nbsp;
                <Label check className="ml-1">
                  Yes
                </Label>
              </Col>
            </Row>
          {user?.selectedUser?.labMainAddress?.serviceProvideWithSameAddress == true && (
                        <>
                          <Row>
                            <Col md={7} className="mt-3">
                              <FormGroup>
                                {/* <Label for={`LocPincode${addressIndex}`}> */}
                                {/* <b>
                                  List all PIN Codes where this location will provide home
                                  collection service
                                </b> */}
                                {/* </Label> */}
                              </FormGroup>
                            </Col>
                          </Row>
                          {/* {Array.isArray(serviceAreaPinCode?.labServiceAreaPinCode?.serviceAreaPinCode) &&
                              serviceAreaPinCode?.labServiceAreaPinCode?.serviceAreaPinCode?.map((row, index) => (
                                <>
                                  <Row key={index}> */}
                          {/* {console.log("row", row)}
                                    <Col md="4"> */}
                          {/* <FormGroup> */}
                          {/* <Input
                                          // id={`locPincode${index}`}
                                          name={`locPincode${index}`}
                                          placeholder="Enter Pincode"
                                          value={row}
                                          type="number"
                                          onChange={(e) => {
                                            handlePincodeChange(index, e);
                                            setServiceAreaPinCode(e.target.value);
                                            // handleLocPinCode(e);
                                          }}
                                          style={{
                                            borderColor: fieldErrors ? "red" : "lightgrey",
                                          }}
                                        /> */}
                          {/* </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                      <FormGroup> */}
                          {/* <Button color="danger" outline style={{ fontSize: "15px", width: "75%" }} onClick={() => addPincode(addressIndex)}>
                                        Add more pincode
                                      </Button> */}
                          {/* </FormGroup>
                                    </Col>
                                  </Row>
                                </> */}
                          {/* // ))} */}
                          {pincodeRow &&
                            pincodeRow.map((row, index) => (
                              <>
                                <Row key={index}>
                                  <Col md="4">
                                    <FormGroup>
                                      {/* <Input
                                        id={`locPincode${index}`}
                                        name="locPincode"
                                        placeholder="Enter Pincode"
                                        value={user?.selectedUser?.labMainAddress?.labServiceAreaPinCode?.serviceAreaPinCode?.join(', ') || ''} 
                                        type="text"
                                        onChange={(e) => {
                                          handlePincodeChange(index, e);
                                          setServiceAreaPinCode(e.target.value);
                                          // handleLocPinCode(e);
                                        }}
                                        style={{
                                          borderColor: fieldErrors.locPincode
                                            ? "red"
                                            : "lightgrey",
                                        }}
                                      /> */}
                                    </FormGroup>
                                  </Col>
                                 
                                </Row>
                              </>
                            ))}
                          <div>
                            {/* Display pin codes separated by comma */}
                            <b>Pin Codes where service is provided : </b>{" "}
                            {serviceAreaPinCode?.labServiceAreaPinCode?.serviceAreaPinCode.join(
                              ", "
                            )}
                          </div>
                          <Row>
                            {/* <div style={{ marginTop: "20px" }}>
                              <Label>
                                <b>Sample Collection Timings</b>
                              </Label>
                              <Table
                                bordered
                                responsive
                                style={{ border: "1px solid #ddd" }}
                              >
                                <thead>
                                  <tr
                                    style={{
                                      background:
                                        "linear-gradient(90deg,royalblue,mediumvioletred)",
                                      color: "white",
                                      textAlign: "center",
                                    }}
                                  >
                                    <th>Day</th>
                                    <th>From</th>
                                    <th>To</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {daysOfWeek.map((day, index) => (
                                    <tr
                                      key={index}
                                      style={{
                                        backgroundColor:
                                          index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                                        textAlign: "center",
                                      }}
                                    >
                                      <td>
                                        <b style={{ color: "#333" }}>{day}</b>
                                      </td>
                                      <td>
                                        <Input
                                          type="time"
                                          value={sampleTimings[day]?.from || ""}
                                          onChange={(e) =>
                                            handleSampleTimingChange(
                                              day,
                                              "from",
                                              e.target.value
                                            )
                                          }
                                          style={{
                                            borderColor: fieldErrors[day]?.from
                                              ? "red"
                                              : "#ddd",
                                            textAlign: "center",
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          type="time"
                                          value={sampleTimings[day]?.to || ""}
                                          onChange={(e) =>
                                            handleSampleTimingChange(
                                              day,
                                              "to",
                                              e.target.value
                                            )
                                          }
                                          style={{
                                            borderColor: fieldErrors[day]?.to
                                              ? "red"
                                              : "#ddd",
                                            textAlign: "center",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                              {fieldErrors.sampleTimings && (
                                <p className="error">
                                  Provide atleast any one sample collection timings.
                                </p>
                              )}
                            </div> */}
                          </Row>
                        </>
                      )}
            <Col>
              <b>Sunday is Holiday?</b> &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="sundayHoliday"
                type="radio"
                checked={user?.selectedUser?.sundayIsHoliday === false}
                onChange={(e) => {
                  setSundayIsHoliday(false);
                }}
                disabled
              />
              &nbsp;
              <Label check className="ml-1">
                No
              </Label>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="sundayHoliday"
                type="radio"
                checked={user?.selectedUser?.sundayIsHoliday === true}
                onChange={(e) => {
                  setSundayIsHoliday(true);
                }}
                disabled
              />
              &nbsp;
              <Label check className="ml-1">
                Yes
              </Label>
            </Col>
          </Row>
        </>
        {/* })} */}
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={12}>
            <FormGroup style={{ marginTop: "25px" }}>
              <Label for="exampleEmail">
                <b>What Licenses you carry for your Establishment:</b>
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {licenses?.map((license, index) => (
            <Col md={4} key={index}>
              <FormGroup>
                <Label>
                  <b>License No. {index + 1}</b>
                </Label>
                <Input
                  name={`Licenses${index + 1}`}
                  value={license.license}
                  onChange={(e) => {
                    handleLicenseChange(index, e.target.value);
                    handleLicenses(e);
                  }}
                  placeholder="Enter License Name"
                  type="text"
                  style={{
                    borderColor:
                      index == 0
                        ? fieldErrors.licenses1
                          ? "red"
                          : "lightgrey"
                        : "lightgrey",
                  }}
                />
                <label
                  htmlFor={`licenses${index + 1}`}
                  style={{
                    cursor: "pointer",
                    color: "#5353e9",
                    textDecoration: "underline",
                  }}
                >
                  Attach License {index + 1}
                </label>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .pdf, .png"
                  id={`licenses${index + 1}`}
                  className="file-upload-input"
                  name={`licenses${index + 1}`}
                  style={{ display: "none" }}
                  onChange={(event) => handleFileChange(event, index)}
                />
                 {license.documentLink && (
    <div>
      <p>{license.documentLink}</p>
      
      {/* Buttons to view and download the file */}
      <div>
      <DownloadForOfflineIcon
        onClick={() => handleDownload(license.documentLink)} // Pass filePath to handleDownload
        style={{ cursor: "pointer", marginRight: "10px" }}
      />

      <DeleteForeverIcon
        onClick={() => handleDelete(license.documentLink, license)} // Pass filePath and item for delete
        style={{ cursor: "pointer" }}
      />
      </div>
      
      {/* If the file is a PDF or an image, we can use an iframe to display it */}
      {license.isFileViewable && (
        <iframe
          src={license.documentLink}
          style={{ width: "100%", height: "400px", marginTop: "10px" }}
          title="License View"
        />
      )}
    </div>
  )}
                
              </FormGroup>
            </Col>
          ))}
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row style={{ marginTop: "30px" }} className="mt-4">
          <Col md={4}>
            <FormGroup>
              <Label
                for="examplePassword"
                style={{
                  color: fieldErrors.nablAccreditation ? "red" : "inherit",
                }}
              >
                <b>Do you have NABL Accreditation?</b>
              </Label>
            </FormGroup>
          </Col>
          <Col md={8}>
            <div className="d-flex align-items-center">
              <FormGroup check className="form-inline mr-3">
                <Input
                  name="nablAccreditation"
                  type="radio"
                  checked={user?.selectedUser?.labNabl === true}
                  onChange={() => {
                    setNablAccreditation(true);
                    handleNablAccreditationChange(true);
                  }}
                />
                <Label check className="mr-3 ml-1">
                  Yes
                </Label>
              </FormGroup>
              <FormGroup
                check
                className="form-inline"
                style={{ marginLeft: "5%" }}
              >
                <Input
                  name="nablAccreditation"
                  type="radio"
                  checked={user?.selectedUser?.labNabl === false}
                  onChange={() => {
                    setNablAccreditation(false);
                    handleNablAccreditationChange(false);
                  }}
                />
                <Label check className="ml-1">
                  No
                </Label>
              </FormGroup>
              {nablAccreditation && (
                <>
                  <span>
                    <label
                      htmlFor="nablAccreditation"
                      style={{
                        cursor: "pointer",
                        color: "rgb(83, 83, 233)",
                        textDecoration: "underline",
                        marginLeft: "20px",
                        color: fieldErrors.NABLreports ? "red" : "blue",
                      }}
                    >
                      Attach NABL Certificate Copy
                    </label>
                  </span>
                  <div>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .pdf, .png"
                      id="nablAccreditation"
                      className="file-upload-input"
                      name="nablAccreditation"
                      // onChange={(event) => handleFileSubmit(event, row.id)}
                      style={{ display: "none" }}
                      onChange={(event) => handleNABLChange(event)}
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  {nablCertificates.length > 0
  ? nablCertificates.map((link, index) => (
      <div key={index}>
        <p>{link}</p>
        <DownloadForOfflineIcon
        onClick={() => handleDownload(link)} // Pass file.filePath to handleDownload
        style={{ cursor: "pointer" }}
      />
      </div>
    ))
  : ""}
                </>
              )}
            </div>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={4}>
            <FormGroup>
              <Label
                for="examplePassword"
                style={{
                  color: fieldErrors.isoCertificate ? "red" : "inherit",
                }}
              >
                <b>Do you have ISO Certification?</b>
              </Label>
            </FormGroup>
          </Col>
          <Col md={8}>
            <div className="d-flex align-items-center">
              <FormGroup check className="form-inline mr-3">
                <Input
                  name="isoCertificate"
                  type="radio"
                  checked={user?.selectedUser?.labIso === true}
                  onChange={() => {
                    setIsoCertificate(true);
                    handleIsoCertification(true);
                  }}
                />
                <Label check className="mr-3 ml-1">
                  Yes
                </Label>
              </FormGroup>
              <FormGroup
                check
                className="form-inline"
                style={{ marginLeft: "5%" }}
              >
                <Input
                  name="isoCertificate"
                  type="radio"
                  checked={user?.selectedUser?.labIso === true}
                  onChange={() => {
                    setIsoCertificate(false);
                    handleIsoCertification(false);
                  }}
                />
                <Label check className="ml-1">
                  No
                </Label>
              </FormGroup>
            </div>
          </Col>
          {isoCertificate && (
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Label>Which ISO standard(s)?</Label>
              <div className="d-flex align-items-center mt-2">
                {isoStandards.map((standard, index) => (
                  <Input
                    key={index}
                    type="text"
                    value={standard}
                    onChange={(e) => handleISOStandardChange(e, index)}
                    placeholder={`ISO Standard ${index + 1}`}
                    style={{ width: "200px", marginRight: "10px" }}
                  />
                ))}
                <Button
                  color="primary"
                  onClick={addISOStandardField}
                  style={{ marginLeft: "10px" }}
                >
                  Add
                </Button>
              </div>
            </div>
          )}
          {isoCertificate && (
            <>
              <span>
                <label
                  htmlFor="isoCertificate"
                  style={{
                    cursor: "pointer",
                    color: "rgb(83, 83, 233)",
                    textDecoration: "underline",
                    // marginLeft: "20px",
                    color: fieldErrors.ISOreports ? "red" : "blue",
                  }}
                >
                  Attach ISO Certificate Copy
                </label>
              </span>{" "}
              <div>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .pdf, .png"
                  id="isoCertificate"
                  className="file-upload-input"
                  name="isoCertificate"
                  // onChange={(event) => handleFileSubmit(event, row.id)}
                  style={{ display: "none" }}
                  onChange={(event) => handleISOChange(event, IOSCertificate)}
                />
              </div>
              &nbsp;&nbsp;&nbsp;
              {isoCertificates.length > 0 ? (
  isoCertificates.map((link, index) => (
    <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
      {/* Display link */}
      <p style={{ margin: 0 }}>
        {link}
      </p>

      {/* Download Icon */}
      <DownloadForOfflineIcon
        onClick={() => handleDownload(link)} // Pass the link to handleDownload
        style={{ cursor: "pointer", marginLeft: "10px" }}
      />
    </div>
  ))
) : (
  <span>No ISO certificates available.</span>
)}

            </>
          )}
        </Row>
        <Row>
          <Col style={{ marginTop: "6%" }}>
            <p
              style={{
                padding: "1%",
                color: fieldErrors.reports ? "red" : "inherit",
              }}
            >
              {/* Please submit <b>7 - 8 sample copies</b> of your past{" "}
              <b>Test Reports</b> of your patients. We need this reports to
              train our AI Model with your Reports. You may <b>hide</b> the
              patient information (Attach one at a time). &nbsp;&nbsp;&nbsp; */}
              <span>
                {/* <input
                  type="file"
                  accept=".jpg, .jpeg, .pdf, .png"
                  id="fileUpload"
                  className="file-upload-input"
                  name="file"
                  onChange={(event) => {
                    handleMultipleChange(event), handleFileUpload(event);
                  }}
                  multiple
                /> */}
                {isLoading == true ? (
                  <div>
                    <Spinner />
                    <br />
                    <p>Please wait your prescription is uploading</p>
                  </div>
                ) : (
                  ""
                )}
              </span>
              <br></br>
              <div>
                {/* <b>Reports : </b> */}
                {labPartnerSampleTest?.map((sampleTestObj, index) => (
  <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
    {/* Display sampleTestLink */}
    <span>
      {sampleTestObj.sampleTestLink}
    </span>

    {/* Download Icon */}
    <DownloadForOfflineIcon
      onClick={() => handleDownload(sampleTestObj.sampleTestLink)} // Pass sampleTestLink to handleDownload
      style={{ cursor: "pointer", marginRight: "10px" }}
    />

    {/* Delete Icon */}
    <DeleteForeverIcon
      onClick={() => handleDelete(sampleTestObj.sampleTestLink, sampleTestObj)} // Pass sampleTestLink and sampleTestObj to handleDelete
      style={{ cursor: "pointer" }}
    />
  </div>
))}

              </div>
              {/* })} */}
            </p>
            <p style={{ display: "flex", marginLeft: "10px" }}>
              <b>Note :</b> If you have any query regarding this, please reach
              out to our Marketing Manager at :{" "}
              <p style={{ color: "blue" }}> &nbsp;+91 9082950730</p>
            </p>
          </Col>
        </Row>
      </Form>
      <center>
        <Button
          onClick={() => handleSubmit(true)}
          style={{
            marginTop: "4%",
            width: "15%",
            backgroundColor: "rgb(11, 184, 6)",
          }}
        >
          Approve
        </Button>
        <Button
          onClick={() => handleSubmit(false)}
          style={{
            marginTop: "4%",
            width: "15%",
            backgroundColor: "rgb(226, 31, 31)",
          }}
        >
          Decline
        </Button>
      </center>
    </>
  );
};
export default MedicalLabApproval;
