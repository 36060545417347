import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import axios from "axios";
import { toast } from "react-toastify";
import "./Admin.css";
import { Card, Form, FormGroup, Label, Modal, ModalHeader, ModalBody } from "reactstrap";
import DoctorProfileApproval from "./DoctorProfileApproval";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CorporateApproval from "./CorporateApproval";
import PhysiotherapistApproval from "./PhysiotheraoistApproval";
import DietitianApproval from "./DietiticanApproval";
import TrainerApproval from "./TrainerApproval";
import DietitianDieasesApproval from "./DietiticanDieasesAprrova;";
import TrainingApproval from "./TrainingApproval";
import MedicalLabApproval from "./MedicalLabApproval";
import GymApproval from "./GymApproval";

const ProfileApprovalScreen = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [partnerName, setPartnerName] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [modalType, setModalType] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/get-partner-type`)
      .then((response) => {
        const transformedRoles = response.data.data.map((role) => {
          let transformedRole = role.partnerType
            .toLowerCase()
            .replace(/_/g, " ");
          transformedRole =
            transformedRole
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ") || role.partnerType;

          if (role.partnerType === "PHYSICIAN") {
            transformedRole = "Physiotherapist";
          } else if (role.partnerType === "TESTLAB") {
            transformedRole = "Medical Lab";
          }

          return {
            id: role.id,
            partnerType: role.partnerType,
            displayName: transformedRole,
          };
        });
        setRoles(transformedRoles);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    const [roleId, roleName] = selectedRole.split(",");
    setSelectedRole(selectedRole);
    setPartnerName(roleName);

    AxiosInstance.get(`admin/get-all-unApproved-partners/${roleId}`)
      .then((response) => {
        processRoleDetails(response, roleName);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleOptionSelect = (event, user, type) => {
    event.preventDefault();  // Prevent the form from submitting and refreshing the page
    setSelectedUser(user);
    setModalType(type);
    setOpenModel(true);
  };

  const processRoleDetails = (data, roleName) => {
    let newOptions = [];
    let userData = [];

    if (Array.isArray(data)) {
      data.forEach((partner) => {
        if (Array.isArray(partner)) {
          partner.forEach((item) => {
            if (roleName === "Doctor" || roleName === "Trainer") {
              if (item.firstName && item.lastName) {
                newOptions.push(`${item.firstName} ${item.lastName}`);
                userData.push(item);
              }
            } else if (roleName === "Corporate") {
              if (item.organizationName) {
                newOptions.push(item.organizationName);
                userData.push(item);
              }
            } else if (roleName === "Physiotherapist") {
              if (item.fullName) {
                newOptions.push(item.fullName);
                userData.push(item);
              }
            } else if (roleName === "Gym") {
              if (item.gymName) {
                newOptions.push(item.gymName);
                userData.push(item);
              }
            }
            else if (roleName === "Dietitian") {
              if (item.firstName && item.lastName) {
                newOptions.push(`${item.firstName} ${item.lastName}`);
                userData.push(item);
              }
            }
            else if (roleName == "Medical Lab"){
              if(item.firstName && item.lastName){
                newOptions.push(`${item.firstName} ${item.lastName}`)
                userData.push(item);
              }
            }
          });
        }
      });
      setSelectedUserData(userData);
    }
  };
  const onCloseOpenModel = () => {
    setOpenModel(false);
    setModalType(null);
  };
  return (
    <>
      <Form
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          marginTop: "25px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        }}
      >
        <h5>Profile Approvals</h5>
        <hr style={{ border: "1px solid #000000", width: "12%" }} />
        <Card className="LabOrderDetailCard">
          <FormGroup>
            <Label for="roleSelect">
              <b>Select Partner:</b>
            </Label>
            <br />
            <select
              className="custom-select"
              id="roleSelect"
              aria-label="Example select with button addon"
              style={{
                textAlign: "center",
                marginTop: "10px",
                width: "40%",
              }}
              onChange={handleRoleChange}
              value={selectedRole} // Ensure this binds to the selectedRole state
            >
              <option value="">Select</option> {/* Placeholder option */}
              {roles?.map((role) => (
                <option key={role.id} value={`${role.id},${role.displayName}`}>
                  {role.displayName}
                </option>
              ))}
            </select>
          </FormGroup>
        </Card>

        <div
          style={{
            padding: "20px",
            border: "1px solid black",
            display: "flex",
            overflow: "auto"
          }}
          className="mt-4"
        >
          <div style={{
            width: "100%"
          }}>
            <Label for="userTable">
              <b>Users:</b>
            </Label>
            <br />

            {(selectedUserData.length > 0 && partnerName == "Medical Lab") ? (
              <table
                className="table table-striped"
                style={{
                  border:"1px solid black"
                }}
              >
                <thead>
                  <tr>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>First Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Last Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Lab Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Partner Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Approval</b></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUserData.map((user, index) => (
                    <tr key={index}>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.firstName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.lastName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.labName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{partnerName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>
                        <button
                          type="button"  // Add type="button" to prevent form submission
                          onClick={(e) => handleOptionSelect(e, user, "approval")}
                          className="btn btn-primary"
                        >
                          <RemoveRedEyeIcon/>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p></p>
            )}

            {(selectedUserData.length > 0 && partnerName == "Dietitian") ? (
              <table
                className="table table-striped"
                style={{
                  border:"1px solid black",
                }}
              >
                <thead>
                  <tr>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>First Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Last Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Hospital Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Partner Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>View</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Approval</b></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUserData.map((user, index) => (
                    <tr key={index}>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.firstName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.lastName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.hospitalName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{partnerName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>
                        <button
                          type="button"  // Add type="button" to prevent form submission
                          onClick={(e) => handleOptionSelect(e, user, "view")}
                          className="btn btn-primary"
                        >
                          <RemoveRedEyeIcon/>
                        </button>
                      </td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>
                        <button
                          type="button"  // Add type="button" to prevent form submission
                          onClick={(e) => handleOptionSelect(e, user, "approval")}
                          className="btn btn-primary"
                        >
                          <RemoveRedEyeIcon/>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p></p>
            )}

            {(selectedUserData.length > 0 && partnerName == "Doctor") ? (
              <table
                className="table table-striped"
                style={{
                  border:"1px solid black",
                }}
              >
                <thead>
                  <tr>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>First Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Last Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Email</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Phone Number</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Partner Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>View</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Approval</b></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUserData.map((user, index) => (
                    <tr key={index}>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.firstName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.lastName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.email}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.phoneNumber}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{partnerName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>
                        <button
                          type="button"  // Add type="button" to prevent form submission
                          onClick={(e) => handleOptionSelect(e, user, "view")}
                          className="btn btn-primary"
                        >
                          <RemoveRedEyeIcon/>
                        </button>
                      </td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>
                        <button
                          type="button"  // Add type="button" to prevent form submission
                          onClick={(e) => handleOptionSelect(e, user, "approval")}
                          className="btn btn-primary"
                        >
                          <RemoveRedEyeIcon/>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p></p>
            )}

            {(selectedUserData.length > 0 && partnerName == "Corporate") ? (
              <table
                className="table table-striped"
                style={{
                  border:"1px solid black",
                }}
              >
                <thead>
                  <tr>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Organization Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Contact Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Email</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Contact Number</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Partner Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>View</b></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUserData.map((user, index) => (
                    <tr key={index}>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.organizationName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.contactName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.email}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.contactMobile}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{partnerName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>
                        <button
                          type="button"  // Add type="button" to prevent form submission
                          onClick={(e) => handleOptionSelect(e, user)}
                          className="btn btn-primary"
                        >
                          <RemoveRedEyeIcon/>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p></p>
            )}

            {(selectedUserData.length > 0 && partnerName == "Physiotherapist") ? (
              <table
                className="table table-striped"
                style={{
                  border:"1px solid black",
                }}
              >
                <thead>
                  <tr>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Full Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Affilated Hospital</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Email</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Contact Number</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Partner Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>View</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Approval</b></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUserData.map((user, index) => (
                    <tr key={index}>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.fullName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.affiliatedHospital == true ? "Yes" : "No"}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.email}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.contactNumber}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{partnerName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>
                        <button
                          type="button"  // Add type="button" to prevent form submission
                          onClick={(e) => handleOptionSelect(e, user, "view")}
                          className="btn btn-primary"
                        >
                          <RemoveRedEyeIcon/>
                        </button>
                      </td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>
                        <button
                          type="button"  // Add type="button" to prevent form submission
                          onClick={(e) => handleOptionSelect(e, user, "approval")}
                          className="btn btn-primary"
                        >
                          <RemoveRedEyeIcon/>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p></p>
            )}

            {(selectedUserData.length > 0 && partnerName == "Gym") ? (
              <table
                className="table table-striped"
                style={{
                  border:"1px solid black",
                }}
              >
                <thead>
                  <tr>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Gym Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Training Programs</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Partner Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>View</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Approval</b></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUserData.map((user, index) => (
                    <tr key={index}>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.gymName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.variousTrainingProgram}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{partnerName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>
                        <button
                          type="button"  // Add type="button" to prevent form submission
                          onClick={(e) => handleOptionSelect(e, user, "view")}
                          className="btn btn-primary"
                        >
                          <RemoveRedEyeIcon/>
                        </button>
                      </td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>
                        <button
                          type="button"  // Add type="button" to prevent form submission
                          onClick={(e) => handleOptionSelect(e, user, "approval")}
                          className="btn btn-primary"
                        >
                          <RemoveRedEyeIcon/>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p></p>
            )}

            {(selectedUserData.length > 0 && partnerName == "Trainer") ? (
              <table
                className="table table-striped"
                style={{
                  border:"1px solid black",
                }}
              >
                <thead>
                  <tr>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>First Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Last Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Email</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Phone Number</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Partner Name</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>View</b></th>
                    <th style={{borderRight:"1px solid black", textAlign:"center"}}><b>Approval</b></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUserData.map((user, index) => (
                    <tr key={index}>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.firstName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.lastName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.email}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{user.phoneNumber}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>{partnerName}</td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>
                        <button
                          type="button"  // Add type="button" to prevent form submission
                          onClick={(e) => handleOptionSelect(e, user, "view")}
                          className="btn btn-primary"
                        >
                          <RemoveRedEyeIcon/>
                        </button>
                      </td>
                      <td style={{borderRight:"1px solid black", textAlign:"center"}}>
                        <button
                          type="button"  // Add type="button" to prevent form submission
                          onClick={(e) => handleOptionSelect(e, user, "approval")}
                          className="btn btn-primary"
                        >
                          <RemoveRedEyeIcon/>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p></p>
            )}

          </div>
        </div>
      </Form>

      {partnerName === "Medical Lab" && selectedUser && (
        <> 
          <Modal isOpen={openModel} toggle={onCloseOpenModel}>
            <ModalHeader toggle={onCloseOpenModel}>
              <b>Medical Lab General Information</b>
            </ModalHeader>
            <ModalBody>
              {selectedUser && (
                <MedicalLabApproval selectedUser={selectedUser} approval="MedicalLab"/>
              )}
            </ModalBody>
          </Modal>
     </>
      )}

      {partnerName === "Doctor" && selectedUser && (
        <>
         {modalType === 'view' && (
          <Modal isOpen={openModel} toggle={onCloseOpenModel}>
            <ModalHeader toggle={onCloseOpenModel}>
              <b>Doctor General Information</b>
            </ModalHeader>
            <ModalBody>
              {selectedUser && (
                <DoctorProfileApproval selectedUser={selectedUser} approval="Doctor"/>
              )}
            </ModalBody>
          </Modal>
        )}

        {/* Modal for DietitianDiseasesApproval */}
        {modalType === 'approval' && (
          <Modal isOpen={openModel} toggle={onCloseOpenModel}>
            <ModalHeader toggle={onCloseOpenModel}>
              <b>Doctor Diseases Information</b>
            </ModalHeader>
            <ModalBody>
              {selectedUser && (
                <DietitianDieasesApproval selectedUser={selectedUser} approval="Doctor"/>
              )}
            </ModalBody>
          </Modal>
        )}
        </>
      )}

      {partnerName === "Corporate" && selectedUser && (
        <> 
          <Modal isOpen={openModel} toggle={onCloseOpenModel}>
            <ModalHeader toggle={onCloseOpenModel}>
              <b>Corporate General Information</b>
            </ModalHeader>
            <ModalBody>
              {selectedUser && (
                <CorporateApproval selectedUser={selectedUser}/>
              )}
            </ModalBody>
          </Modal>
     </>
      )}
      {partnerName === "Physiotherapist" && selectedUser && (
       <>         {modalType === 'view' && (
        <Modal isOpen={openModel} toggle={onCloseOpenModel}>
          <ModalHeader toggle={onCloseOpenModel}>
            <b>Physiotherapist General Information</b>
          </ModalHeader>
          <ModalBody>
            {selectedUser && (
              <PhysiotherapistApproval selectedUser={selectedUser} approval="Physiotherapist"/>
            )}
          </ModalBody>
        </Modal>
      )}

      {/* Modal for DietitianDiseasesApproval */}
      {modalType === 'approval' && (
        <Modal isOpen={openModel} toggle={onCloseOpenModel}>
          <ModalHeader toggle={onCloseOpenModel}>
            <b>Physiotherapist Diseases Information</b>
          </ModalHeader>
          <ModalBody>
            {selectedUser && (
              <DietitianDieasesApproval selectedUser={selectedUser} approval="Physiotherapist"/>
            )}
          </ModalBody>
        </Modal>
      )}</>
      )}
      {partnerName === "Gym" && selectedUser && (
        <>
        {modalType === 'view' && (
         <Modal isOpen={openModel} toggle={onCloseOpenModel}>
           <ModalHeader toggle={onCloseOpenModel}>
             <b>Gym General Information</b>
           </ModalHeader>
           <ModalBody>
             {selectedUser && (
               <GymApproval selectedUser={selectedUser} approval="Gym"/>
             )}
           </ModalBody>
         </Modal>
       )}

       {/* Modal for DietitianDiseasesApproval */}
       {modalType === 'approval' && (
         <Modal isOpen={openModel} toggle={onCloseOpenModel}>
           <ModalHeader toggle={onCloseOpenModel}>
             <b>Gym Training Information</b>
           </ModalHeader>
           <ModalBody>
             {selectedUser && (
               <TrainingApproval selectedUser={selectedUser} approval="Gym"/>
             )}
           </ModalBody>
         </Modal>
       )}
       </>
      )}
       {partnerName === "Dietitian" && selectedUser && (
        <>
        {modalType === 'view' && (
            <Modal isOpen={openModel} toggle={onCloseOpenModel}>
              <ModalHeader toggle={onCloseOpenModel}>
                <b>Dietician General Information</b>
              </ModalHeader>
              <ModalBody>
                {selectedUser && (
                  <DietitianApproval selectedUser={selectedUser} approval="Dietitian"/>
                )}
              </ModalBody>
            </Modal>
          )}

          {/* Modal for DietitianDiseasesApproval */}
          {modalType === 'approval' && (
            <Modal isOpen={openModel} toggle={onCloseOpenModel}>
              <ModalHeader toggle={onCloseOpenModel}>
                <b>Dietician Diseases Information</b>
              </ModalHeader>
              <ModalBody>
                {selectedUser && (
                  <DietitianDieasesApproval selectedUser={selectedUser}  approval="Dietitian"/>
                )}
              </ModalBody>
            </Modal>
          )}
      </>
      )}
        {partnerName === "Trainer" && selectedUser && (
        <>         {modalType === 'view' && (
          <Modal isOpen={openModel} toggle={onCloseOpenModel}>
            <ModalHeader toggle={onCloseOpenModel}>
              <b>Trainer General Information</b>
            </ModalHeader>
            <ModalBody>
              {selectedUser && (
                <TrainerApproval selectedUser={selectedUser} approval="Trainer"/>
              )}
            </ModalBody>
          </Modal>
        )}

        {/* Modal for DietitianDiseasesApproval */}
        {modalType === 'approval' && (
          <Modal isOpen={openModel} toggle={onCloseOpenModel}>
            <ModalHeader toggle={onCloseOpenModel}>
              <b>Training Approval Information</b>
            </ModalHeader>
            <ModalBody>
              {selectedUser && (
                <TrainingApproval selectedUser={selectedUser} approval="Trainer"/>
              )}
            </ModalBody>
          </Modal>
        )}</>
      )}
    </>
  );
};

export default ProfileApprovalScreen;

