import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

const SampleTestReportModal = ({ open, onClose, data }) => {
  const [fileList, setFileList] = useState(data);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoadingView, setIsLoadingView] = useState(false);

  // Handle opening the View Modal
  const handleView = (file) => {
    setIsLoadingView(true);
    setSelectedFile(file);
    setViewModalOpen(true);
    const authToken = window.sessionStorage.getItem("token");
    const viewApi = "lab-partner/download-lab-file/LAB_DOCUMENT";
    const encodedUrl = encodeURIComponent(file.sampleTestLink);
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${viewApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch document: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        setSelectedFile({ ...file, blobUrl });
      })
      .catch((error) => {
        toast.error(`Error fetching the file: ${error}`);
        setViewModalOpen(false); // Close modal if fetch fails
      })
      .finally(() => {
        setIsLoadingView(false); // Stop loader
      });
  };

  // Handle closing the View Modal
  const closeViewModal = () => {
    if (selectedFile?.blobUrl) {
      window.URL.revokeObjectURL(selectedFile.blobUrl); // Clean up the blob URL
    }
    setSelectedFile(null);
    setViewModalOpen(false);
  };

  const handleDownload = (filePath) => {
    setIsDownloading(true);
    const downloadApi = "lab-partner/download-lab-file/LAB_DOCUMENT";
    const encodedUrl = encodeURIComponent(filePath);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              const downloadLink = document.createElement("a");
              downloadLink.href = blobUrl;
              downloadLink.download = filename;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(blobUrl);
            });
          }
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      })
      .finally(() => {
        setIsDownloading(false); // Stop loader
      });
  };

  const handleDelete = (filePath, file) => {
    const id = file.id;
    const deleteApi = "lab-partner/delete-lab-file";
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${deleteApi}/${id}/SAMPLE_TEST_REPORT`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: "Deleted Successfully!",
            text: "The document was deleted successfully.",
            icon: "success",
            confirmButtonText: "OK",
            customClass: {
              container: "swal-container-above-modal", // Custom class for Swal
            },
          });
          setFileList((prev) =>
            prev.filter((item) => item.sampleTestLink !== filePath)
          );
        } else {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
      })
      .catch((error) => {
        toast.error(`Error deleting the file: ${error}`);
      });
  };

  console.log("file", fileList);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "80vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              borderBottom: "1px solid #ccc",
              pb: 1,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              File List
            </Typography>
            <IconButton onClick={onClose} sx={{ color: "#000" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          {fileList.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>File Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Document Path</strong>
                    </TableCell>
                    <TableCell>
                      <strong>View</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Download</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Delete</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fileList.map((file, index) => (
                    <TableRow key={index}>
                      <TableCell>Sample Document</TableCell>
                      <TableCell>{file.sampleTestLink}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleView(file)}>
                          <VisibilityIcon color="action" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleDownload(file.sampleTestLink)}
                        >
                          <DownloadForOfflineIcon color="primary" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleDelete(file.sampleTestLink, file)}
                        >
                          <DeleteForeverIcon color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            // Empty Message
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40vh",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" color="textSecondary" sx={{ mb: 2 }}>
                No Files found.
              </Typography>
              <SentimentDissatisfiedIcon fontSize="large" color="disabled" />
            </Box>
          )}

          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      {isDownloading && (
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2000,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {viewModalOpen && (
        <Modal open={viewModalOpen} onClose={closeViewModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              maxHeight: "90vh",
              overflowY: "auto",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 3,
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
                borderBottom: "1px solid #ccc",
                pb: 1,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                Viewing: Sample Document
              </Typography>
              <IconButton onClick={closeViewModal} sx={{ color: "#000" }}>
                <CloseIcon />
              </IconButton>
            </Box>
            {isLoadingView ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : selectedFile?.blobUrl ? (
              <iframe
                src={selectedFile.blobUrl}
                title={selectedFile?.fileName}
                style={{ width: "100%", height: "60vh", border: "none" }}
              />
            ) : (
              <Typography color="error">Failed to load document.</Typography>
            )}
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={closeViewModal}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default SampleTestReportModal;
